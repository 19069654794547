<style lang="less" scoped>
</style>
<template>
  <div>
    <el-button @click="handleClick('QueryA')">
      To Router QueryA
    </el-button>
    <el-button @click="handleClick('QueryB')">
      To Router QueryB
    </el-button>
  </div>
</template>
<script>
export default {
  name: 'QueryDemo',
  methods: {
    handleClick (name) {
      this.$router
        .push({ name: 'query-demo-detail', query: { id: name } })
        .catch(_ => {})
    }
  }
}
</script>
